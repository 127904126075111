const sample = `Maj 2021					
					
1	sob.	--			
2	niedz.	--			
3	pon.	--			
4	wt.	--			
5	śr.	Jan Kowalski			
6	czw.	Olimpia Pawlak			
7	pt.	Konrad Trzciński			
8	sob.	--			
9	niedz.	--			
10	pon.	Konrad Trzciński			
11	wt.	Konrad Trzciński			
12	śr.	Jan Kowalski			
13	czw.	Anna Nowak		
14	pt.	Olimpia Pawlak			
15	sob.	--			
16	niedz.	--			
17	pon.	Anna Nowak		
18	wt.	Jarosław Marciniak			
19	śr.	Konrad Trzciński			
20	czw.	Olimpia Pawlak			
21	pt.	Jan Kowalski			
22	sob.	--			
23	niedz.	--			
24	pon.	Konrad Trzciński			
25	wt.	Jarosław Marciniak			
26	śr.	Jan Kowalski			
27	czw.	Olimpia Pawlak			
28	pt.	Jarosław Marciniak			
29	sob.	--			
30	niedz.	--			
31	pon.	Anna Nowak		`;

export default sample;
